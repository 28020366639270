import { combineReducers } from "redux";

import { TOGGLE_CALENDLY_MODAL_ACTION } from "../actions/calendly";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const modal = (state = { open: false, url: "" }, { type, payload }) => {
  switch (type) {
    case TOGGLE_CALENDLY_MODAL_ACTION:
      return { ...payload };
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        url: payload?.user?.digitalExecutionCalendlyLink || state.url,
      };
    default:
      return state;
  }
};

export default combineReducers({
  modal,
});
