import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { createMiddleware } from "redux-beacon";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import amplitude from "amplitude-js";
import Amplitude from "@redux-beacon/amplitude";

import createRootReducer from "../reducers";
import rootSaga from "../sagas";
import { TABOOLA_CONFIG, TaboolaAnalytics } from "./analytics/taboola-config";
import { BING_CONFIG, BingAnalytics } from "./analytics/bing-config";
import { AMPLITUDE_CONFIG } from "./analytics/amplitude-config";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const taboola = TaboolaAnalytics();
const taboolaMiddleware = createMiddleware(TABOOLA_CONFIG, taboola);
const bing = BingAnalytics();
const bingMiddleware = createMiddleware(BING_CONFIG, bing);

const amplitudeApiKey = window.env.AMPLITUDE_API_KEY;
const amplitudeInstance = amplitude.getInstance("willful");
amplitudeInstance.init(amplitudeApiKey, null, {
  includeReferrer: true, // track referrer
  includeUtm: true, // track UTM properties
  // "saveParamsReferrerOncePerSession = false" is necessary when using AmplitudeAPI without Segment
  saveParamsReferrerOncePerSession: false, // avoid saving includeReferrer and includeUtm once per session
  unsetParamsReferrerOnNewSession: true, // unset params referrer on new session
  deviceIdFromUrlParam: true, // the SDK will parse Device ID values from the URL parameter amp_device_id if available
});
const amplitudeTarget = Amplitude({ instance: amplitudeInstance });
const amplitudeMiddleware = createMiddleware(AMPLITUDE_CONFIG, amplitudeTarget);

window.amplitude = amplitudeInstance;

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth"], // TODO: should conserve language?
};

let middleware = [routerMiddleware(history), sagaMiddleware];

if (window.env.segment_client_write_key || window.env.AMPLITUDE_API_KEY) {
  middleware.push(amplitudeMiddleware);
}

const isProd = window.env.FE_ENV === "production";
if (isProd) {
  middleware = [...middleware, bingMiddleware, taboolaMiddleware];
}

let enhancers = [];
if (
  typeof window === "object" &&
  window.env.REDUX_DEV_TOOLS === "enabled" &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  enhancers = [...enhancers, window.__REDUX_DEVTOOLS_EXTENSION__()];
}

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
);

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(...middleware), ...enhancers),
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return { store, persistor };
}
