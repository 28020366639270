import { createSelector, createStructuredSelector } from "reselect";

import {
  selectIsActivePlanBundle,
  selectActivePlan,
  selectNotarizationStatus,
  selectIsNotarialWill,
  selectHasUserPaid,
  selectIsUpgradableUser,
} from "./plans";
import { selectIsCompleteAllSections } from "./section-completion";
import { selectIsFrenchSelected } from "./language";
import {
  selectAssignedPlans,
  selectNumberOfAvailableInvites,
} from "./purchases";
import { selectIsAboutYouComplete } from "./about-you";
import { selectIsEssentialsOnlyProvinceSelected } from "./provinces";

const selectCanUserUpgrade = createSelector(
  selectIsAboutYouComplete,
  selectIsUpgradableUser,
  (isCompleteAboutYou, hasUpgradeablePlan) =>
    isCompleteAboutYou && hasUpgradeablePlan,
);

export const selectYourPlanProps = createStructuredSelector({
  activePlan: selectActivePlan,
  isBundlePlanSelected: selectIsActivePlanBundle,
  canUserUpgrade: selectCanUserUpgrade,
  isPaid: selectHasUserPaid,
  assignedPlans: selectAssignedPlans,
  numberOfAvailablePlans: selectNumberOfAvailableInvites,
  isNotarialWill: selectIsNotarialWill,
  notarizationStatus: selectNotarizationStatus,
  isFrenchSelected: selectIsFrenchSelected,
  isCompleteAllSections: selectIsCompleteAllSections,
  isEssentialsOnlyProvince: selectIsEssentialsOnlyProvinceSelected,
});
