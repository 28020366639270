import { put, takeEvery, all, select } from "redux-saga/effects";

import { displayModal } from "../../actions/modal";
import { MODAL_DIGITAL_EXECUTION } from "../../constants/modal";

import { toggleCalendlyModalAction } from "../../actions/calendly";
import { CALENDLY_DIGITAL_EXECUTION_URL } from "../../constants/routes";
import { selectCalendlyModalData } from "../../selectors/calendly";
import {
  PURCHASE_DIGITAL_EXECUTION_ACTION,
  BOOK_DIGITAL_EXECUTION_ACTION,
} from "../../actions/addons/digital-execution";

function* purchaseDigitalExecutionWorker() {
  yield put(displayModal(MODAL_DIGITAL_EXECUTION));
}

function* bookDigitalExecutionWorker() {
  const singleUseCalendlyLink = yield select(selectCalendlyModalData);
  yield put(
    toggleCalendlyModalAction({
      open: true,
      url: singleUseCalendlyLink.url || CALENDLY_DIGITAL_EXECUTION_URL,
    }),
  );
}

function* watchPurchaseDigitalExecution() {
  yield takeEvery(
    PURCHASE_DIGITAL_EXECUTION_ACTION,
    purchaseDigitalExecutionWorker,
  );
}

function* watchBookDigitalExecution() {
  yield takeEvery(BOOK_DIGITAL_EXECUTION_ACTION, bookDigitalExecutionWorker);
}

export default function* watchDigitalExecutionSagas() {
  yield all([watchPurchaseDigitalExecution(), watchBookDigitalExecution()]);
}
