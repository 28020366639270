import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Form, reduxForm } from "redux-form";
import Box from "@material-ui/core/Box";

import { ErrorMessage } from "../../ui/Error";
import {
  BACKUPS_FIELD_ID,
  BACKUPS_FORM_ID,
  CHARITY_BACKUP,
  CHARITY_ID_FIELD_ID,
  CONTACT_ID_FIELD_ID,
  DONATIONS_CUSTOM_CHARITY,
  ENTRY_TYPE_FIELD_ID,
  PERSON_BACKUP,
  REGISTERED_NAME_FIELD_ID,
} from "../../../constants/forms";
import {
  useValidateCharityMemo,
  useValidateContactPresentMemo,
} from "../../ui/inputs/validations";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import { Body } from "../../ui/Typography";
import {
  selectAllContacts,
  selectSpouseContactId,
} from "../../../selectors/contacts";
import {
  openModalContactForContactable,
  selectContactFromDropdown,
} from "../../../actions/contacts";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import { MODAL_ADD_CONTACT_BACKUP } from "../../../constants/modal";
import { CharityDropdownSelect } from "../../ui/inputs/DropdownCharitySelect";
import { FormButtons } from "../../ui/Form";
import { selectCharitiesOptionList } from "../../../selectors/donations";
import { selectFormBackups } from "../../../selectors/backup";
import TextInput from "../../ui/inputs/TextInput";
import { BACKUP_CONTACTABLE_MODEL } from "../../../constants/contacts";
import {
  openModalCustomCharity,
  removeCharityBackupOnProgress,
  removePersonBackupOnProgress,
  updateCharityBackupOnProgress,
} from "../../../actions/allocations";
import {
  selectIsPrimaryBeneficiarySpouse,
  selectIsTotalAllocationsForSpouseOnly,
} from "../../../selectors/allocations";

const getContactId = (field) => field?.contactId || field?.contact?.id;

const BackupContactSelect = ({
  fieldId,
  fieldIndex,
  field,
  contacts,
  translations,
  backupFormValues,
}) => {
  const allSelectedContacts = backupFormValues
    .filter(({ type, _destroy }) => {
      return type === PERSON_BACKUP && _destroy !== true;
    })
    .map((fieldObj) => getContactId(fieldObj));

  const fieldBackupId = field?.id;
  const fieldContactId = getContactId(field);
  const filteredContacts = contacts.filter((person) => {
    const contactId = person.contact.id;
    const keepSelectedContact = fieldContactId
      ? contactId === fieldContactId
      : false;
    return keepSelectedContact || !allSelectedContacts.includes(contactId);
  });

  const [selectedContactId, setSelectedContactId] = useState(fieldContactId);

  useEffect(() => {
    // when initial contactId is updated, update selectedContactId
    if (fieldContactId) {
      setSelectedContactId(fieldContactId);
    }
  }, [fieldContactId]);

  const dispatch = useDispatch();
  const spouseContactId = useSelector(selectSpouseContactId);
  const isPrimaryBeneficiarySpouse = useSelector(
    selectIsPrimaryBeneficiarySpouse,
  );
  const isTotalAllocationsForSpouseOnly = useSelector(
    selectIsTotalAllocationsForSpouseOnly,
  );

  const disabledContactIds = [];
  if (isPrimaryBeneficiarySpouse || isTotalAllocationsForSpouseOnly) {
    disabledContactIds.push(spouseContactId);
  }

  const validateContact = useValidateContactPresentMemo(translations);

  const handleSelectContact = (contactId, isSecondaryContact = false) => {
    dispatch(
      selectContactFromDropdown(
        contactId,
        fieldBackupId,
        BACKUP_CONTACTABLE_MODEL,
        isSecondaryContact,
      ),
    );
    setSelectedContactId(contactId);
  };

  const handleAddNewContact = (contactableId) => {
    dispatch(
      openModalContactForContactable(
        null,
        MODAL_ADD_CONTACT_BACKUP,
        contactableId,
        false,
        BACKUPS_FORM_ID,
        BACKUPS_FORM_ID,
        fieldIndex,
      ),
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <DropdownContactSelect
          name={`${fieldId}.${CONTACT_ID_FIELD_ID}`}
          label={translations.backupRecipientLabel}
          contacts={filteredContacts}
          disabledContactIds={disabledContactIds}
          selectedContactId={selectedContactId}
          onSelect={handleSelectContact}
          onAddNew={handleAddNewContact}
          validator={validateContact}
          includeMinorChildren
        />
      </Box>
    </Box>
  );
};

const BackupCharitySelect = ({
  field = {},
  fieldId,
  translations,
  charities,
  backupFormValues,
}) => {
  const dispatch = useDispatch();
  const [selectedCharityId, setSelectedCharityId] = useState(null);

  const isCustomInput = field[ENTRY_TYPE_FIELD_ID] === DONATIONS_CUSTOM_CHARITY;
  const validateCharity = useValidateCharityMemo(translations);
  const currentlyUsedIds = backupFormValues
    .filter(({ type }) => type === CHARITY_BACKUP)
    .map(({ charityId }) => charityId);

  function handleAddCustom() {
    dispatch(openModalCustomCharity());
  }

  const handleSelectCharity = (charityId) => {
    dispatch(
      updateCharityBackupOnProgress({
        backup: field,
        charityId,
        isCustomInput,
      }),
    );
    setSelectedCharityId(charityId);
  };

  return (
    <>
      {isCustomInput ? (
        <Box mb={1}>
          <Box mb={1}>
            <Field
              name={`${fieldId}.${REGISTERED_NAME_FIELD_ID}`}
              label={translations.charityNameLabel}
              placeholder={translations.charityNamePlaceholder}
              component={TextInput}
              noMargin
              disabled
            />
          </Box>
        </Box>
      ) : (
        <Box mb={1}>
          <Field
            id={fieldId}
            name={`${fieldId}.${CHARITY_ID_FIELD_ID}`}
            label={translations.backupRecipientLabel}
            placeholder={translations.selectCharityLabel}
            component={CharityDropdownSelect}
            selectedCharityId={selectedCharityId}
            disabledCharityIds={currentlyUsedIds}
            validate={validateCharity}
            optionList={charities}
            onSelect={handleSelectCharity}
            onCustomInputClick={handleAddCustom}
            marginBottom={1}
          />
        </Box>
      )}
    </>
  );
};

const BackupsFieldArrayRenderer = ({
  fields,
  translations,
  handleChange,
  defaultFieldObject = {},
  canAddMore = true,
  helperText,
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector(selectAllContacts);
  const charities = useSelector(selectCharitiesOptionList);
  const backupFormValues = useSelector(selectFormBackups);

  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);

        // _destroy indicates to rails that field is to be deleted
        // requires rerenderOnEveryChange={true} on <FieldArray />
        // performance hit on large lists < 10 should be fine
        if (field && field._destroy === true) {
          return null;
        }

        const isCharityBackupField = field.type === CHARITY_BACKUP;

        const handleRemoveField = () => {
          if (field.id) {
            if (isCharityBackupField) {
              dispatch(removeCharityBackupOnProgress(field));
            } else {
              dispatch(removePersonBackupOnProgress(field));
            }
          }
          handleChange(`${fieldId}._destroy`, true);
        };

        const selectTypeField = isCharityBackupField ? (
          <BackupCharitySelect
            fieldId={fieldId}
            field={field}
            translations={translations}
            charities={charities}
            backupFormValues={backupFormValues}
          />
        ) : (
          <BackupContactSelect
            fieldId={fieldId}
            fieldIndex={index}
            field={field}
            contacts={contacts}
            translations={translations}
            backupFormValues={backupFormValues}
          />
        );

        return (
          <Box key={fieldId}>
            {selectTypeField}
            <Box mb={2} width="100%" display="flex" flexDirection="row-reverse">
              <SmallTextButton
                text={translations.remove}
                className="qa-remove"
                onClick={handleRemoveField}
              />
            </Box>
          </Box>
        );
      })}

      {helperText && <Body>{helperText}</Body>}

      {canAddMore && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box mb={1}>
            <SecondaryButton
              text={translations.button.addPerson}
              fullWidth
              displayPlusIcon
              className="qa-add-person"
              type="button"
              onClick={() =>
                fields.push({
                  ...defaultFieldObject,
                  type: PERSON_BACKUP,
                })
              }
            />
          </Box>
          <Box>
            <SecondaryButton
              text={translations.button.addCharity}
              fullWidth
              displayPlusIcon
              className="qa-add-charity"
              type="button"
              onClick={() =>
                fields.push({
                  ...defaultFieldObject,
                  type: CHARITY_BACKUP,
                })
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const BackupsForm = ({
  error,
  change,
  handleSubmit,
  backLink,
  onSkip,
  translations,
  isLoading,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="mt-3">
      <Box mb={2}>
        <FieldArray
          rerenderOnEveryChange
          handleChange={change}
          name={BACKUPS_FIELD_ID}
          component={BackupsFieldArrayRenderer}
          translations={translations}
        />
        {error && <ErrorMessage error={error} />}
      </Box>
      <FormButtons
        qaFormId="backup"
        isLoading={isLoading}
        onSkip={onSkip}
        backLink={backLink}
        translations={translations}
      />
    </Form>
  );
};

export default reduxForm({
  form: BACKUPS_FORM_ID,
  destroyOnUnmount: false, // required to preserve redux form state when adding beneficiary
})(BackupsForm);
