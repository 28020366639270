import R from "ramda";

import English from "./en.json";
import Ontario from "./provinces/ontario/en.json";
import Alberta from "./provinces/alberta/en.json";
import BritishColumbia from "./provinces/british-columbia/en.json";
import Saskatchewan from "./provinces/saskatchewan/en.json";
import Manitoba from "./provinces/manitoba/en.json";
import NewBrunswick from "./provinces/new-brunswick/en.json";
import NovaScotia from "./provinces/nova-scotia/en.json";
import Quebec from "./provinces/quebec/en.json";
import NewfoundlandAndLabrador from "./provinces/newfoundland-and-labrador/en.json";
import PrinceEdwardIsland from "./provinces/prince-edward-island/en.json";

import French from "./fr.json";
import QuebecFr from "./provinces/quebec/fr.json";

export const getConfigByProvinceCode = (provinceCode, lang) => {
  switch (provinceCode) {
    case "ON": {
      return Ontario;
    }
    case "AB": {
      return Alberta;
    }
    case "BC": {
      return BritishColumbia;
    }
    case "SK": {
      return Saskatchewan;
    }
    case "MB": {
      return Manitoba;
    }
    case "NB": {
      return NewBrunswick;
    }
    case "NS": {
      return NovaScotia;
    }
    case "QC": {
      return lang === "fr" ? QuebecFr : Quebec;
    }
    case "NL": {
      return NewfoundlandAndLabrador;
    }
    case "PE": {
      return PrinceEdwardIsland;
    }
    default: {
      return {};
    }
  }
};

const deepMergeAll = (list) => R.reduce(R.mergeDeepRight, {}, list);

export const getAccordionTranslationsByProvince = (
  lang,
  provinceCode,
  listOfQuestionKeys,
) => {
  const baseTranslations = getMultiplePageTranslationsByProvince(
    lang,
    provinceCode,
    ["faq"],
  );

  const translations = R.reduce(
    (acc, curr) => {
      if (R.includes(curr, listOfQuestionKeys)) {
        acc.push(baseTranslations[curr]);
      }
      return acc;
    },
    [],
    Object.keys(baseTranslations),
  );

  return translations;
};

export const getMultiplePageTranslationsByProvince = (
  lang,
  provinceCode,
  listOfPageKeys,
) => {
  if (!listOfPageKeys) return {};
  const provinceTranslations = getConfigByProvinceCode(provinceCode, lang);
  const provincePageTranslations = listOfPageKeys.map((pageKey) =>
    R.propOr({}, pageKey, provinceTranslations),
  );
  const basePageTranslations = listOfPageKeys.map((pageKey) =>
    getPageTranslations(lang, pageKey),
  );

  // Since the translations are arrays of objects for each page key,
  // merge the arrays of translations into two objects.
  const mergedProvincePageTranslations = deepMergeAll(provincePageTranslations);
  const mergedBasePageTranslations = deepMergeAll(basePageTranslations);

  // Now that we have two objects, we can deep merge the province translations
  // onto the base translations to allow for recursive merging.
  return R.mergeDeepRight(
    mergedBasePageTranslations,
    mergedProvincePageTranslations,
  );
};

export const getPageTranslations = (lang, pageKey) => {
  if (!pageKey) return {};
  switch (lang) {
    case "en":
      return English[pageKey] || {};
    case "fr":
      return French[pageKey] || {};
    default:
      return {};
  }
};

export const getTranslationByKey = (lang, pageKey, subKey) => {
  if (!pageKey || !subKey) return "";
  switch (lang) {
    case "en":
      return English[pageKey][subKey] || {};
    case "fr":
      return French[pageKey][subKey] || {};
    default:
      return "";
  }
};
