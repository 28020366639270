import React from "react";
import { Box } from "@material-ui/core";

import { H4, SmallBody, SmallTextLink } from "./Typography";
import { CONTACTS_PATH } from "../../constants/routes";
import NotificationsIcon from "./icons/NotificationsIcon";

const AppointeesNotificationCard = ({ translations }) => (
  <Box
    bgcolor="willfulLightPurple.main"
    border={1}
    borderColor="border.main"
    px={1.5}
    py={1.5}
    mb={2}
    borderRadius="4px"
  >
    <Box mb={1.5} display="flex">
      <Box mr={1} maxWidth="75%">
        <Box mb={0.5}>
          <H4 align="left">{translations.notifyYourAppointees}</H4>
        </Box>
        <SmallBody>{translations.letYourAppointeesKnow}</SmallBody>
      </Box>
      <Box display="flex" height={1} justifyContent="center" ml="auto">
        <NotificationsIcon width={60} height={60} />
      </Box>
    </Box>
    <Box display="flex">
      <SmallTextLink
        to={CONTACTS_PATH}
        text={translations.sendNotifications}
        displayArrowRight
      />
    </Box>
  </Box>
);

export default AppointeesNotificationCard;
