enum FlagName {
  ASSOCIATE_CONTACTS = "FEATURE_ASSOCIATE_CONTACTS",
  CONTACTS_EMAIL = "FEATURE_CONTACTS_EMAIL",
  CONTACT_RELATIONSHIP = "FEATURE_CONTACTS_RELATIONSHIP",
  MERGE_CONTACTS = "FEATURE_MERGE_CONTACTS",
  DIGITAL_EXECUTION = "FEATURE_DIGITAL_EXECUTION",
  EXECUTED_DOC_SURVEY = "FEATURE_EXECUTED_DOC_SURVEY",
}

const ENABLED = "enabled";

declare const window: {
  env: FlagName;
} & Window;

const flag = (flagName: FlagName) => window.env[flagName] === ENABLED;

/*** Feature Flag Methods ***/
export const featureAssociateContactsEnabled = () =>
  flag(FlagName.ASSOCIATE_CONTACTS);

export const featureContactsEmailEnabled = () => flag(FlagName.CONTACTS_EMAIL);

export const featureContactsRelationshipEnabled = () =>
  flag(FlagName.CONTACT_RELATIONSHIP);

export const featureMergeContactsEnabled = () => flag(FlagName.MERGE_CONTACTS);

export const featureDigitalExecutionEnabled = () =>
  flag(FlagName.DIGITAL_EXECUTION);

export const featureExecutedDocSurveyEnabled = () =>
  flag(FlagName.EXECUTED_DOC_SURVEY);
