import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@material-ui/core";
import DigitalExecutionButton from "./DigitalExecutionButton";

import { featureDigitalExecutionEnabled } from "../../../utilities/featureFlags";
import { selectDigitalExecutionStage } from "../../../selectors/digital-execution";
import { selectHasUserPaid } from "../../../selectors/plans";
import { selectIsUserFromBC } from "../../../selectors/provinces";
import { analyticsClickedAddonButton } from "../../../actions/analytics";
import {
  purchaseDigitalExecutionAction,
  bookDigitalExecutionAction,
} from "../../../actions/addons/digital-execution";
import {
  DIGITAL_EXECUTION_NOT_PURCHASED,
  DIGITAL_EXECUTION_PURCHASED,
} from "../../../constants/addons";

export default function DigitalExecutionWrapper({
  mt = 1.5,
  mb = 0,
  fullWidth = true,
}) {
  const dispatch = useDispatch();

  const notPaid = !useSelector(selectHasUserPaid);
  const notBCUser = !useSelector(selectIsUserFromBC);
  const stage = useSelector(selectDigitalExecutionStage);

  if (!featureDigitalExecutionEnabled()) return null;
  if (notPaid || notBCUser) return null;

  const purchased = stage !== DIGITAL_EXECUTION_NOT_PURCHASED;

  function handleClick(event) {
    event.preventDefault();

    switch (stage) {
      case DIGITAL_EXECUTION_NOT_PURCHASED:
        dispatch(purchaseDigitalExecutionAction());
        break;
      case DIGITAL_EXECUTION_PURCHASED:
        dispatch(bookDigitalExecutionAction());
        break;
      default:
        return;
    }

    dispatch(
      analyticsClickedAddonButton({ purchased, name: "Digital Execution" }),
    );
  }

  return (
    <Box mt={mt} mb={mb} width={1}>
      <DigitalExecutionButton
        stage={stage}
        onClick={handleClick}
        fullWidth={fullWidth}
      />
    </Box>
  );
}
