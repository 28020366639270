import R from "ramda";
import { select, takeEvery, call, put, delay } from "redux-saga/effects";
import {
  getFormValues,
  untouch,
  change,
  initialize,
  stopSubmit,
} from "redux-form";
import { push } from "connected-react-router";
import queryString from "query-string";
import { submitFormRequest } from "./forms";

import {
  submitSignup,
  submitInvitedUserSignup,
  submitDeleteAccount,
  submitLogin,
  submitLogout,
  submitForgotPassword,
  submitResetPassword,
  submitUpdatePassword,
  getValidateToken,
  postEmailAvailabilityRequest,
  submitGoogleAuthLogin,
  postForgotSsoPasswordRequest,
  submitDeleteGoogleSSOAccount,
} from "../api/auth";
import {
  signupAnalytics,
  loginAnalytics,
  logoutAnalytics,
  isInvitedUserAnalytics,
  taplyticsIdentifyAnalytics,
  viewSignupAnalytics,
  referralVisitAnalytics,
  analyticsResetUser,
  hotjarIdentifyAnalytics,
  analyticsGoogleSSOAttempt,
} from "../actions/analytics";
import {
  selectUserId,
  selectInviteToken,
  selectTokenUpdatedAt,
  selectEmailFromQueryParams,
} from "../selectors/auth";
import {
  SIGNUP_FORM_ID,
  LOGIN_FORM_ID,
  FORGOT_PASSWORD_FORM_ID,
  RESET_PASSWORD_FORM_ID,
  UPDATE_PASSWORD_FORM_ID,
  OLD_PASSWORD_FIELD_ID,
  PASSWORD_FIELD_ID,
  PASSWORD_CONF_FIELD_ID,
  DELETE_ACCOUNT_FORM_ID,
  EMAIL_FIELD_ID,
  GOOGLE_SSO_ALREADY_EXISTS_PLEASE_LOGIN,
  DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID,
} from "../constants/forms";
import {
  VALIDATE_TOKEN_TYPE,
  SUBMIT_SIGNUP_TYPE,
  SUBMIT_LOGIN_TYPE,
  SUBMIT_LOGOUT_TYPE,
  SUBMIT_FORGOT_PASSWORD_TYPE,
  SUBMIT_RESET_PASSWORD_TYPE,
  SUBMIT_UPDATE_PASSWORD_TYPE,
  clearState,
  VALIDATE_TOKEN_EXPIRATION_TYPE,
  validateTokenExpirationTime,
  INITIALIZE_SIGNUP_FORM_TYPE,
  CHECK_EMAIL_AVAILABILITY_TYPE,
  SUBMIT_GOOGLE_AUTH_LOGIN_TYPE,
  SET_AUTH_METHOD,
  INITIALIZE_LOGIN_FORM_TYPE,
  FORGOT_SSO_PASSWORD_REQUEST,
  SUBMIT_GOOGLE_AUTH_DELETE_TYPE,
} from "../actions/auth";

import { requestSequence } from "./requests";

import { selectQueryParams } from "../selectors/location";
import { selectLanguageCode } from "../selectors/about-you";
import { closeModal, displayModal } from "../actions/modal";
import { selectIsInvitedUser } from "../selectors/invite";
import { selectSelectedProvince } from "../selectors/provinces";
import { LOGIN_PATH, RESET_SUCCESS_PATH } from "../constants/routes";
import { selectHasUserPaid } from "../selectors/plans";
import { MODAL_CHANGE_SSO_PASSWORD } from "../constants/modal";

// In environments where Segment client key is not set, window.analytics.user will not exist
const isAnalyticsReady = () => window.analytics && window.analytics.user;

function* signupRequest() {
  const signupFormValues = yield select(getFormValues(SIGNUP_FORM_ID));

  const { email } = signupFormValues;
  const lang = yield select(selectLanguageCode);

  const isInvitedUser = yield select(selectIsInvitedUser);
  const segmentAnonymousId = isAnalyticsReady()
    ? window.analytics.user().anonymousId()
    : "";

  if (isInvitedUser) {
    // TODO:
    // FE Validate province is valid

    const inviteToken = yield select(selectInviteToken);
    const { errors, userId } = yield call(submitFormRequest, {
      apiCall: submitInvitedUserSignup,
      formId: SIGNUP_FORM_ID,
      values: {
        ...signupFormValues, // TODO: will be claim invite signup form which will include provinceId...
        lang,
        inviteToken,
        anonymousId: segmentAnonymousId,
      },
    });

    if (!errors) {
      yield call(signupRequestAnalytics, {
        isInvitedUser,
        email,
        userId,
      });
    }
  } else {
    const { errors, userId } = yield call(submitFormRequest, {
      apiCall: submitSignup,
      formId: SIGNUP_FORM_ID,
      values: {
        lang,
        anonymousId: segmentAnonymousId,
        ...signupFormValues,
      },
    });

    if (!errors) {
      yield call(signupRequestAnalytics, {
        isInvitedUser,
        email,
        userId,
      });
    }
  }
}

// TODO: All of these should be rolled into a single signupAnalytics saga as it's a little redundant
// to have two sagas for this here.
function* signupRequestAnalytics({
  isInvitedUser,
  email,
  firstName, // TODO: first + Last won't be included anymore post signup redesign
  lastName,
  userId,
}) {
  const { code } = yield select(selectSelectedProvince);

  yield put(isInvitedUserAnalytics(isInvitedUser));

  const isPaidUser = false;
  const isSessionPurchaser = false;
  yield put(signupAnalytics({ email, firstName, lastName, userId }));
  yield put(taplyticsIdentifyAnalytics({ email, userId, provinceCode: code }));
  yield put(
    hotjarIdentifyAnalytics({
      email,
      userId,
      isInvitedUser,
      isPaidUser,
      isSessionPurchaser,
    }),
  );
}

export function* deleteAccountRequest() {
  const { errors } = yield call(submitFormRequest, {
    apiCall: submitDeleteAccount,
    formId: DELETE_ACCOUNT_FORM_ID,
  });
  if (!errors) {
    yield put(closeModal());
    yield put(clearState({}));
  }
}

function* loginRequest() {
  const signInFormValues = yield select(getFormValues(LOGIN_FORM_ID));
  const { email } = signInFormValues;
  const lang = yield select(selectLanguageCode);
  const segmentAnonymousId = isAnalyticsReady()
    ? window.analytics.user().anonymousId()
    : "";

  const { error, userId } = yield call(submitFormRequest, {
    apiCall: submitLogin,
    formId: LOGIN_FORM_ID,
    values: {
      ...signInFormValues,
      anonymousId: segmentAnonymousId,
      lang,
    },
  });

  if (!error) {
    yield call(loginRequestAnalytics, {
      email,
      userId,
    });

    yield put({ type: VALIDATE_TOKEN_EXPIRATION_TYPE });
  }
}

function* googleAuthLoginRequest({ payload }) {
  const { credential, requestType } = payload;
  const lang = yield select(selectLanguageCode);
  const isInvitedUser = yield select(selectIsInvitedUser);
  try {
    const response = yield call(submitFormRequest, {
      apiCall: submitGoogleAuthLogin,
      values: {
        lang,
        credential,
        requestType,
      },
    });
    if (response) {
      const { errors, email, userId, existingUser } = response;

      if (errors) {
        // Trigger Google SSO attempt failed event
        yield put(
          analyticsGoogleSSOAttempt({
            type: existingUser ? "existing" : "new",
            status: "failed",
          }),
        );
        if (errors === GOOGLE_SSO_ALREADY_EXISTS_PLEASE_LOGIN) {
          if (requestType === "login") {
            yield put(change(LOGIN_FORM_ID, EMAIL_FIELD_ID, email));
          }
          return yield put({
            type: SET_AUTH_METHOD,
            payload: { mustAuthViaPassword: true, email },
          });
        }
      }

      if (!errors) {
        // Trigger Google SSO attempt successful event
        yield put(
          analyticsGoogleSSOAttempt({
            type: existingUser ? "existing" : "new",
            status: "success",
          }),
        );
        if (existingUser) {
          yield call(loginRequestAnalytics, {
            email,
            userId,
          });
        } else {
          yield call(signupRequestAnalytics, {
            isInvitedUser,
            email,
            userId,
          });
        }
      }
    }
    return yield put({ type: VALIDATE_TOKEN_EXPIRATION_TYPE });
  } catch (error) {
    return yield put({ type: VALIDATE_TOKEN_EXPIRATION_TYPE });
  }
}

function* googleAuthDeleteRequest({ payload }) {
  const { errors } = yield call(submitFormRequest, {
    apiCall: submitDeleteGoogleSSOAccount,
    formId: DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID,
    values: {
      credential: payload.credential,
    },
  });
  if (errors) {
    yield put(
      stopSubmit(DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID, { _error: errors.base }),
    );
  }
  if (!errors) {
    yield put(closeModal());
    yield put(clearState({}));
  }
}

function* loginRequestAnalytics({ email, userId }) {
  const { code } = yield select(selectSelectedProvince);
  const isPaidUser = yield select(selectHasUserPaid);
  const isInvitedUser = yield select(selectIsInvitedUser);
  const isSessionPurchaser = false;

  yield put(loginAnalytics({ email, userId }));
  yield put(taplyticsIdentifyAnalytics({ email, userId, provinceCode: code }));
  yield put(
    hotjarIdentifyAnalytics({
      email,
      userId,
      isInvitedUser,
      isPaidUser,
      isSessionPurchaser,
    }),
  );
}

function* logoutRequest() {
  try {
    yield call(requestSequence, submitLogout, {
      type: "logout",
      meta: { request: "submit" },
    });
    const userId = yield select(selectUserId);
    yield put(logoutAnalytics(userId));

    // reset sessionId to avoid mixing events if the user log with a different account
    yield put(analyticsResetUser());
  } finally {
    yield put(clearState({ redirectTo: LOGIN_PATH }));
  }
}

function* forgotPasswordRequest() {
  try {
    yield call(submitFormRequest, {
      apiCall: submitForgotPassword,
      formId: FORGOT_PASSWORD_FORM_ID,
    });
  } finally {
    yield put(push(RESET_SUCCESS_PATH));
  }
}

function* updatePasswordRequest() {
  const formValues = yield select(getFormValues(UPDATE_PASSWORD_FORM_ID));
  const { oldPassword, password } = formValues;
  const response = yield call(submitFormRequest, {
    apiCall: submitUpdatePassword,
    formId: UPDATE_PASSWORD_FORM_ID,
    values: {
      oldPassword,
      password,
    },
  });

  if (!R.pathOr(null, ["errors", "oldPassword"])(response)) {
    yield put(change(UPDATE_PASSWORD_FORM_ID, OLD_PASSWORD_FIELD_ID, ""));
    yield put(untouch(UPDATE_PASSWORD_FORM_ID, OLD_PASSWORD_FIELD_ID, ""));
  }
  yield put(change(UPDATE_PASSWORD_FORM_ID, PASSWORD_FIELD_ID, ""));
  yield put(change(UPDATE_PASSWORD_FORM_ID, PASSWORD_CONF_FIELD_ID, ""));
  yield put(untouch(UPDATE_PASSWORD_FORM_ID, PASSWORD_FIELD_ID, ""));
  yield put(untouch(UPDATE_PASSWORD_FORM_ID, PASSWORD_CONF_FIELD_ID, ""));
}

function* resetPasswordRequest() {
  const rawQueryParams = yield select(selectQueryParams);
  const { reset_password_token } = yield call(
    queryString.parse,
    rawQueryParams,
  );
  const { password } = yield select(getFormValues(RESET_PASSWORD_FORM_ID));
  yield call(submitFormRequest, {
    apiCall: submitResetPassword,
    formId: RESET_PASSWORD_FORM_ID,
    values: {
      password,
      resetPasswordToken: reset_password_token,
    },
  });
}

function* validateTokenRequest() {
  yield call(requestSequence, getValidateToken, {
    type: "validateToken",
    meta: { request: "fetch" },
  });
}

function* setAuthExpirationTime() {
  const minutes = window.env.DEVISE_TOKEN_LIFESPAN_IN_MINUTES;
  const intervalTime = parseInt(minutes, 10) * 60 * 1000;
  const lastTokenAt = yield select(selectTokenUpdatedAt());
  if (lastTokenAt > 0) {
    const now = new Date().getTime() / 1000;
    const expirationTime = lastTokenAt + intervalTime / 1000;
    if (now > expirationTime) {
      yield delay(10000); // Add 10 extra seconds
      window.location.reload(false);
    } else {
      yield delay(60000); // each minute checks the token
      yield put(validateTokenExpirationTime());
    }
  }
}

function* initializeLogin() {
  const email = yield select(selectEmailFromQueryParams);

  // initialize the email field if URL has a query param called "email"
  if (email) {
    yield put(initialize(LOGIN_FORM_ID, { [EMAIL_FIELD_ID]: email }));
  }

  // reset auth attempt when the login form is initialized
  yield put({
    type: SET_AUTH_METHOD,
    payload: { mustAuthViaPassword: false, email: "" },
  });
}

function* initializeSignup() {
  const email = yield select(selectEmailFromQueryParams);

  // initialize the email field if URL has a query param called "email"
  if (email) {
    yield put(initialize(SIGNUP_FORM_ID, { [EMAIL_FIELD_ID]: email }));
  }

  // reset auth attempt when the signup form is initialized
  yield put({
    type: SET_AUTH_METHOD,
    payload: { mustAuthViaPassword: false, email: "" },
  });

  // NOTE: these where previously trigerred in the SignupPage file
  yield put(viewSignupAnalytics());

  const growSurfId = window.env.growsurf_campaign_id;
  const campaignId = document.cookie
    .split("; ")
    .find((row) => row.startsWith(growSurfId));

  if (campaignId) {
    yield put(referralVisitAnalytics({ referral: campaignId }));
  }
}

function* handleCheckEmailAvailability({ payload }) {
  const formId = R.propOr(SIGNUP_FORM_ID, "formId")(payload);
  const formValues = yield select(getFormValues(formId));
  if (formValues) {
    const email = R.propOr("", "email")(formValues);
    const { errors } = yield call(submitFormRequest, {
      apiCall: postEmailAvailabilityRequest,
      values: {
        email,
      },
    });

    if (errors && errors.email) {
      // show error message on email field if email already exists
      yield put(
        stopSubmit(formId, {
          email: errors.email.replace("{EMAIL}", email),
        }),
      );
    }
  }
}

function* handleForgotSsoPassword() {
  yield call(submitFormRequest, {
    apiCall: postForgotSsoPasswordRequest,
  });
  yield put(displayModal(MODAL_CHANGE_SSO_PASSWORD));
}

export function* watchValidateToken() {
  yield takeEvery(VALIDATE_TOKEN_TYPE, validateTokenRequest);
}

export function* watchSubmitSignup() {
  yield takeEvery(SUBMIT_SIGNUP_TYPE, signupRequest);
}

export function* watchSubmitLogin() {
  yield takeEvery(SUBMIT_LOGIN_TYPE, loginRequest);
}

export function* watchSubmitGoogleAuthLogin() {
  yield takeEvery(SUBMIT_GOOGLE_AUTH_LOGIN_TYPE, googleAuthLoginRequest);
}

export function* watchSubmitGoogleAuthDelete() {
  yield takeEvery(SUBMIT_GOOGLE_AUTH_DELETE_TYPE, googleAuthDeleteRequest);
}

export function* watchSubmitLogout() {
  yield takeEvery(SUBMIT_LOGOUT_TYPE, logoutRequest);
}

export function* watchSubmitForgotPassword() {
  yield takeEvery(SUBMIT_FORGOT_PASSWORD_TYPE, forgotPasswordRequest);
}

export function* watchSubmitResetPassword() {
  yield takeEvery(SUBMIT_RESET_PASSWORD_TYPE, resetPasswordRequest);
}

export function* watchSubmitUpdatePassword() {
  yield takeEvery(SUBMIT_UPDATE_PASSWORD_TYPE, updatePasswordRequest);
}

export function* watchExpirationTime() {
  yield takeEvery(VALIDATE_TOKEN_EXPIRATION_TYPE, setAuthExpirationTime);
}

export function* watchFetchSignUpForm() {
  yield takeEvery(INITIALIZE_SIGNUP_FORM_TYPE, initializeSignup);
}

export function* watchFetchLoginForm() {
  yield takeEvery(INITIALIZE_LOGIN_FORM_TYPE, initializeLogin);
}

export function* watchCheckEmailAvailability() {
  yield takeEvery(CHECK_EMAIL_AVAILABILITY_TYPE, handleCheckEmailAvailability);
}

export function* watchForgotSsoPassword() {
  yield takeEvery(FORGOT_SSO_PASSWORD_REQUEST, handleForgotSsoPassword);
}
