import { createSelector } from "reselect";

const featureExternalLoginEnabled = () =>
  window.env.FEATURE_EXTERNAL_LOGIN === "enabled";
export const selectIsFeatureExternalLoginEnabled = createSelector(
  featureExternalLoginEnabled,
  (isFeatureExternalLoginEnabled) => isFeatureExternalLoginEnabled,
);

const collapsedPromoFieldEnabled = () =>
  window.env.FEATURE_COLLAPSED_PROMO_FIELD === "enabled";
export const selectIsCollapsedPromoFieldEnabled = createSelector(
  collapsedPromoFieldEnabled,
  (isCollapsedPromoFieldEnabled) => isCollapsedPromoFieldEnabled,
);
