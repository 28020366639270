import React, { useEffect } from "react";

import { reduxForm } from "redux-form";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";

import EmailField from "../ui/inputs/EmailField";
import PasswordField from "../ui/inputs/PasswordField";
import { PrimaryButton } from "../ui/Button";
import {
  SmallBody,
  H2,
  MiceType,
  SmallTextLink,
  StrikethroughParagraph,
} from "../ui/Typography";
import { ErrorMessage } from "../ui/Error";
import { LOGIN_FORM_ID, PASSWORD_FIELD_ID } from "../../constants/forms";
import { useValidateLegacyPasswordMemo } from "../ui/inputs/validations";
import IconBackground from "../ui/willful-icon-background.svg";
import LockSmall from "../ui/icons/LockSmall";
import { InfoBox } from "../ui/InfoBox";
import { selectLanguageCode } from "../../selectors/language";
import { selectUserAuthMethod } from "../../selectors/auth";
import { initializeLoginForm, submitGoogleAuthLogin } from "../../actions/auth";

const BackgroundIcons = styled(Box)`
  background-repeat: repeat-x;
  overflow: auto;
  background-position-y: center;
  height: 500px;
  background-image: url(${IconBackground});
`;

const MobileTabletFormContainer = styled(Box)`
  box-shadow: 0px 0px 16px 0px #4a4a4a40;
`;

const Form = styled("form")`
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const LoginForm = ({
  error,
  handleSubmit,
  isLoading,
  getStartedLink,
  forgotPasswordLink,
  translations,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const emailExists =
    initialValues.email !== undefined && initialValues.email.length > 0;
  const currentLanguage = useSelector(selectLanguageCode);
  const localeSelected = currentLanguage === "en" ? "en-CA" : "fr-CA";
  const userAuthMethod = useSelector(selectUserAuthMethod);
  const { mustAuthViaPassword } = userAuthMethod;
  const shouldShowEmailExistsInfo = emailExists || mustAuthViaPassword;

  const handleGoogleLogin = (response) => {
    const data = {
      credential: response.credential,
    };
    dispatch(submitGoogleAuthLogin(data));
  };
  const handleGoogleLoginFailure = (googleError) => {
    console.error({ googleError });
  };

  useEffect(() => {
    dispatch(initializeLoginForm());
  }, [dispatch]);
  return (
    <BackgroundIcons
      flexGrow={1}
      display="flex"
      width="100%"
      bgcolor="willfulxxxLightBlue.main"
      justifyContent="center"
    >
      <Grid container spacing={0} justify="center" alignItems="stretch">
        <Grid item xl={4} lg={5} md={9} sm={12}>
          <Form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width={480}
              py={4.5}
              px={isMobile ? "20px" : 0}
            >
              <MobileTabletFormContainer
                px={2}
                py={2}
                borderRadius={8}
                bgcolor="willfulWhite.main"
                justifyContent="center"
              >
                <H2 component="h1" align="left">
                  {translations.pageTitle}
                </H2>
                <Box display="flex" justifyContent="left" my={1}>
                  <SmallBody>
                    {translations.getStartedLabel}
                    <Box component="span" ml={0.2}>
                      <SmallTextLink
                        to={getStartedLink}
                        text={translations.getStarted}
                      />
                    </Box>
                  </SmallBody>
                </Box>
                {shouldShowEmailExistsInfo && (
                  <Box my={2}>
                    <InfoBox
                      border
                      padding={1.5}
                      paragraph={translations.userAlreadyExistsInfo}
                      linkUrl={forgotPasswordLink}
                      linkText={translations.reset}
                      isReset
                    />
                  </Box>
                )}
                <>
                  <Box mb={2} display="flex" justifyContent="center">
                    <GoogleLogin
                      width="304px"
                      useOneTap
                      shape="rectangular"
                      theme="outline"
                      size="large"
                      text="continue_with"
                      logo_alignment="center"
                      locale={localeSelected}
                      onSuccess={handleGoogleLogin}
                      onError={handleGoogleLoginFailure}
                    />
                  </Box>
                  <Box textAlign="center">
                    <StrikethroughParagraph text={translations.or} />
                  </Box>
                </>
                <EmailField translations={translations} />
                <PasswordField
                  name={PASSWORD_FIELD_ID}
                  validation={useValidateLegacyPasswordMemo(translations)}
                  translations={translations}
                />
                <Box my={1} textAlign="right">
                  <SmallTextLink
                    to={forgotPasswordLink}
                    text={translations.forgotPassword}
                  />
                </Box>
                <Box mt={2}>
                  <PrimaryButton
                    fullWidth
                    displayArrowRight
                    isLoading={isLoading}
                    loadingLabel={translations.loadingLabel}
                    text={translations.button.submit}
                  />
                </Box>
                <ErrorMessage
                  error={error}
                  linkUrl={forgotPasswordLink}
                  linkText={translations.reset}
                />
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Box alignSelf="center" mt={0.25} mr={0.25}>
                    <LockSmall />
                  </Box>
                  <MiceType margin="16px 0" color="willfulLightGrey.main">
                    {translations.security}
                  </MiceType>
                </Box>
              </MobileTabletFormContainer>
            </Box>
          </Form>
        </Grid>
      </Grid>
    </BackgroundIcons>
  );
};

export default reduxForm({ form: LOGIN_FORM_ID })(LoginForm);
