import React, { AnchorHTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../selectors/translations";
import { selectUserEmail, selectRailsUserId } from "../../selectors/auth";
import styled from "styled-components";

interface ExecutionTrackerProps {
  provinceCode: string;
}

const ExecutionTrackerSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ExecutionTrackerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

const Hyperlink = styled.a<AnchorHTMLAttributes<HTMLAnchorElement>>`
  font-size: 14px;
  font-weight: 700;
  color: #0064dc;
  text-decoration: none;
`;

const Separator = styled.p`
  margin-left: 8px;
  margin-right: 8px;
`;

const ExecutionTracker = ({ provinceCode }: ExecutionTrackerProps) => {
  const undefinedValue = "xxxxx"; // this is the fallback value for the parameter
  const translations = useSelector(
    selectTranslations(["documents", "provinces"]),
  );

  const email = useSelector(selectUserEmail);
  const userId = useSelector(selectRailsUserId) as number;
  const province = translations[provinceCode];

  const encodedURI = (flag: boolean) => {
    const params = new URLSearchParams({
      email: email ? (email as string) : undefinedValue,
      user_id: userId ? userId.toString() : undefinedValue,
      province: province ? province : undefinedValue,
      ...(flag ? { executed_yes: "yes" } : { executed_no: "no" }),
    });
    return `https://willfulwills.typeform.com/to/vXk94HiY#${params.toString()}`;
  };

  return (
    <ExecutionTrackerSection>
      <Title>{translations.tracking.title}</Title>
      <ExecutionTrackerContent>
        <Hyperlink
          href={encodedURI(true)}
          id="ExecutionTrackerYes"
          target="_blank"
        >
          {translations.tracking.yes}
        </Hyperlink>
        <Separator>|</Separator>
        <Hyperlink
          href={encodedURI(false)}
          id="ExecutionTrackerNo"
          target="_blank"
        >
          {translations.tracking.no}
        </Hyperlink>
      </ExecutionTrackerContent>
    </ExecutionTrackerSection>
  );
};

export default ExecutionTracker;
